.report-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 24px;

    width: 100%;

    min-width: 400px;

}

.report-modal .report-page {
    width: 100%;

}

.report-modal .report-page #form-page {
    display: flex;
    flex-direction: column;
    gap: 12px;

}

.report-modal .report-page #form-page #form-map> :first-child {
    white-space: nowrap;
    font-size: calc(var(--main-input-font-size) - 4pt);
    color: var(--secondary-gray);

    max-width: 90%;

    overflow: hidden;
    text-overflow: ellipsis;
}

.report-modal #modal-map {
    position: relative;

    height: 300px;
    width: 100%;

    padding: 6px;

}
.report-modal #modal-map > div {
    height: 100%;
}

#noise-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 24px;

}
#noise-page .audio-controls {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
}
#noise-page .audio-controls #recording-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 90%;

    gap: 12px;

}
#noise-page .audio-controls #recording-content #button-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 6px;

    background-color: var(--terciary-gray);

    padding: 12px;

    border-radius: 10px;

}
#noise-page .audio-controls #recording-content #button-container .record-button {
    padding: 48px;

    font-size: 24pt;

    width: fit-content;
    aspect-ratio: 1/1;
}

#noise-page .audio-controls #recording-content #button-container #recording-status {
    display: flex;

    gap: 6px;

}
#noise-page .audio-controls #recording-content #button-container #recording-status > div {
    background-color: var(--main-gray);
    padding: 6px;

    font-weight: bold;

    border-radius: 5px;

    color: var(--dark-gray);
}

#noise-page .audio-controls #recording-content #recording-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 6px;

    background-color: var(--terciary-gray);

    padding: 12px;

    border-radius: 10px;

    width: 100%;

}
#noise-page .audio-controls #recording-content #recording-info #decibels-info {
    display: flex;
    align-items: center;
    gap: 12px;

}
#noise-page .audio-controls #recording-content #recording-info #decibels-info >span {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 6px;

    border-radius: 5px;

    background-color: var(--base-color);

    font-weight: bold;

    color: var(--dark-gray);
}

#noise-page .audio-controls #recording-content #recording-info .audio-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding: 6px;

    gap: 12px;

    /* background-color: color-mix(in srgb, var(--main-green), transparent 80%); */
    /* background-color: var(--dark-gray); */

    border-radius: 5px;

}
#noise-page .audio-controls #recording-content #recording-info .audio-container #download {
    max-width: 50%;
}

#noise-page .audio-controls #recording-content #recording-info .audio-container audio {
    width: 100%;
}

#noise-page .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 75%;

    margin-top: 24px;

    gap: 12px;
}

@media screen and (max-width: 460px) {
    .report-modal {
        min-width: 100%;
    }
}