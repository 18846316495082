:root {
	--main-input-font-size: 14pt;
}

.main-input {
	display: flex;
	flex-direction: column;

	width: 100%;

	gap: 2px;
}

.main-input .description {
	white-space: nowrap;
	font-size: calc(var(--main-input-font-size) - 4pt);
	color: var(--secondary-gray);

	max-width: 90%;

	overflow: hidden;
	text-overflow: ellipsis;
}

.main-input .input-container {
	position: relative;
	display: flex;
	align-items: center;

	border: none;
	border-radius: 5px;

	background-color: var(--terciary-gray);

	transition: background-color 0.1s;
}

.main-input .input-container input {
	width: 100%;

	background-color: transparent;

	font-size: var(--main-input-font-size);
	border: none;
	border-radius: 5px;
	padding: 6px;

	color: var(--dark-gray);
}

.main-input .input-container input:focus {
	outline: none;
	border: none;
}

.main-input .input-container input::placeholder {
	color: var(--secondary-gray);
}

.main-input .input-container:has(input:disabled, textarea:disabled) {
	background-color: var(--secondary-gray);
}

.main-input .input-container:has(input:disabled, textarea:disabled) input {
	background-color: var(--secondary-gray);
}

.main-input .input-container:has(input:disabled, textarea:disabled) .input-buttons {
	display: none
}

.main-input .input-container:has(input:disabled, textarea:disabled):hover .input-buttons {
	display: flex;
}

.main-input .input-container:has(input[type='number']:not(:disabled)).input-buttons {
	right: calc(0% + 25px);
}

.main-input .input-container:has(textarea:not(:disabled)).input-buttons {
	right: calc(0% + 15px);
}

.main-input .input-container textarea {
	width: 100%;

	font-size: var(--main-input-font-size);
	border: none;
	border-radius: 5px;
	padding: 6px;

	background-color: var(--mid-gray);

	transition: background-color 0.1s;
}

.main-input .input-container textarea:not(:focus):hover {
	background-color: var(--main-gray);
}

.main-input .input-container textarea:focus {
	outline: none;
	border: none;
}

.main-input .input-container textarea::placeholder {
	color: var(--secondary-gray);
}

.main-input .input-container.input-buttons {
	position: absolute;
	right: calc(0% + 5px);
	bottom: -40%;

	display: flex;
	gap: 3px;
}

.main-input .input-container.input-buttons .main-icon-button {
	width: 25px;
	height: 25px;
	font-size: 10pt;

	box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.main-input .input-container.input-icon {
	padding-right: 4px;
}

/* When the input is selected */
.main-input &:not(.error):has(input:focus, textarea:focus) .input-container {
	background-color: color-mix(in srgb, var(--highlight-color), white 70%);
}

.main-input &:not(.error):has(input:hover:not(:disabled, :focus)) .input-container {
	background-color: var(--main-gray);
}

.main-input &.error .input-container {
	background-color: color-mix(in srgb, red, transparent 80%);
}

.main-input .hint {
	color: red;
	font-size: 10pt;
}

.text-input-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	border-radius: 5px;

	gap: 6px;

}
.text-input-container>div:first-child {
	display: flex;
	align-items: center;
	gap: 6px;
}

.text-input-container:hover:not(.editing):not(.not-editable) {
	cursor: pointer;

	background-color: var(--mid-gray);
	outline: 6px solid var(--mid-gray);
}

.text-input-container.editing {
	background-color: var(--mid-gray);
	outline: 6px solid var(--mid-gray);
}

.text-input-container input {
	width: 100%;

	font-size: var(--main-input-font-size);
	border: none;
	border-radius: 5px;
	padding: 6px;
}

.text-input-container input:focus {
	outline: none;
	border: none;
}

.text-input-container input::placeholder {
	color: var(--secondary-gray);
}

.text-input-container textarea {
	width: 100%;

	font-size: var(--main-input-font-size);
	border: none;
	border-radius: 5px;
	padding: 6px;

	resize: vertical;	
}

.text-input-container textarea:focus {
	outline: none;
	border: none;
}

.text-input-container textarea::placeholder {
	color: var(--secondary-gray);
}

.text-input-container.error .input-container {
	border-color: red;
}

.text-input-container .hint {
	color: red;
	font-size: 10pt;
}

.text-input-container .text-input-buttons {
	display: flex;
	gap: 3px;
}