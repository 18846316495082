.main-button.check {
	--_background: var(--ok-green);
	--_background-hover: var(--secondary-ok-green);
	--_color: white;
}

.main-button.red {
	--_background: var(--main-red);
	--_background-hover: var(--secondary-red);
	--_color: white;
}

.main-button.yellow {
	--_background: var(--main-warning);
	--_background-hover: var(--secondary-warning);
	--_color: white;
}

.main-button.pre-alarm {
	--_background: var(--main-pre-alarm);
	--_background-hover: var(--secondary-warning);
	--_color: white;
}

.main-button.gray {
	--_background: var(--secondary-gray);
	--_background-hover: var(--main-gray);
	--_color: white;
}

.main-button.dark-gray {
	--_background: var(--dark-gray);
	--_background-hover: black;
	--_color: white;
}

.main-button.white {
	--_background: white;
	--_background-hover: rgb(218, 218, 218);
}

.main-button.main:not(.no-fill) {
	--_background: var(--highlight-color);
	--_background-hover: color-mix(in srgb, var(--highlight-color) 90%, black);
	--_color: white;
}

.main-button.red-hover {
	--_background-hover: red;
	--_color-hover: white;
}

.main-button.alt-hover {
	--_background-hover: var(--mid-gray);
}

.main-button {
	--_background: white;
	--_background-hover: var(--terciary-gray);
	--_border: var(--main-gray);
	--_color: var(--main-gray);
	--_color-hover: var(--main-gray);

	display: flex;
	align-items: center;
	justify-content: center;

	gap: 6px;

	cursor: pointer;

	font-size: 100%;
	font-weight: bold;

	background-color: var(--_background);
	padding: 6px;
	border: none;
	border-radius: 5px;

	height: 30px;
	aspect-ratio: 1/1;

	color: var(--_color);

	white-space: nowrap;

	box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);

	transition: background-color 0.2s, color 0.2s;
}

.main-button.border {
	border: solid 1px var(--_border);
}

.main-button:disabled {
	cursor: not-allowed !important;
}

.main-button.plain-text {
	all: unset;
	cursor: pointer;
	color: var(--_color);

	&:hover {
		text-decoration: underline;
	}
}

.main-button.full-width {
	aspect-ratio: unset;
	width: 100%;
}

.main-button.full-height {
	aspect-ratio: unset;
	height: 100%;
}

.main-button.no-shadow {
	box-shadow: none !important;
}

.main-button.no-padding {
	padding: 0;
}

.main-button.no-border-radius {
	border-radius: 0;
}

.main-button:hover {
	background-color: var(--_background-hover);
	color: var(--_color-hover);
}

.main-button:disabled {
	filter: brightness(80%);

	&:hover {
		background-color: var(--_background);
	}
}

.main-button.check,
.main-button.red,
.main-button.main,
.main-button.yellow,
.main-button.pre-alarm,
.main-button.gray,
.main-button.dark-gray {
	color: white;
	border-color: white;
}

.main-button.no-border {
	border: none;
}

.main-button.round {
	border-radius: 100%;
}

.main-button.no-fill {
	border: none;
	background-color: transparent;
	box-shadow: none;
}

.main-button.no-fill .main {
	color: var(--main-green);
	font-size: 16pt;
}

.main-button.no-fill:not(:disabled):hover {
	background-color: var(--terciary-gray) !important;
}

.main-button.no-fill:not(:disabled).alt-hover:hover {
	background-color: var(--mid-gray) !important;
}

.main-button.no-fill:disabled:hover {
	background-color: transparent !important;
}

.main-button.fit-content {
	width: fit-content;
	aspect-ratio: unset;
}

a:has(.main-button) {
	text-decoration: none !important;
}