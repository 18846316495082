.map-statistics {
    position: absolute;
    z-index: 1000;

    bottom: 2%;
    left: 1%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    color: white;

    font-size: 16pt;

    height: 50px;
    aspect-ratio: 1/1;

    /* background-color: var(--secondary-gray); */

    border-radius: 5px;

    transition: background-color 0.2s, height 0.3s, width 0.3s;

    /* box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2); */

}

.map-statistics.extended {
    height: 35%;
    width: 25%;

    color: black;
}

.map-statistics .stats {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    background-color: white;

    overflow: hidden;

    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);

    border-radius: 5px;
}

.map-statistics .stats .stats-top {
    display: flex;
    align-items: center;

    gap: 6px;

    width: 100%;

    padding: 6px;
}

.map-statistics .stats .stats-top>span {
    font-size: 12pt;

    font-weight: bold;
}

.map-statistics .stats .stats-content {
    overflow: hidden;
}

.map-statistics .stats #schedule {
    display: flex;
    flex-direction: column;

    align-items: start;

    padding: 6px;

    overflow: hidden;
}

.map-statistics .stats #schedule #chart {
    position: relative;
    width: 99%;
    height: 99%;
    /* max-height: 250px; */
}

.map-statistics .stats #schedule:has(#schedule_list_container) {
    height: 100%;
}

.map-statistics .stats #schedule #schedule_list_container {
    display: flex;
    flex-direction: column;

    gap: 12px;

    width: 100%;

    overflow: hidden;
}

.map-statistics .stats #schedule #schedule_list_container .title {
    font-size: 16pt;
}

.map-statistics .stats #schedule #schedule_list_container #list {
    display: flex;
    flex-direction: column;

    gap: 6px;

    overflow-y: scroll;

    font-size: 12pt;
}

.map-statistics .stats #schedule #schedule_list_container #list .schedule {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 6px;

    border-radius: 5px;

    width: 100%;

    background-color: var(--terciary-gray);

}

.map-statistics .stats #schedule #schedule_list_container #list .schedule :first-child {
    font-family: monospace;

    background-color: white;

    padding: 3px 6px;

    border-radius: 5px;

    color: rgb(62, 62, 62);

    font-weight: bold;
}

@media screen and (max-width: 460px) {
    .map-statistics.extended {
        width: calc(100% - 57px);
        height: 250px;

        aspect-ratio: unset;
    }
}