body {
    overflow: hidden;
}

.dashboard-alt {
    position: absolute;

    top: 0%;
    left: 0%;

    display: flex;
    flex-direction: column;
    gap: 36px;

    height: 100%;
    width: 100%;

    overflow: hidden;
}

.dashboard-alt #map {
    position: relative;

    min-height: 100px;
    height: 1000px;
    width: calc(100% - 70px);

}

.dashboard-alt #map>div {
    height: 100%;
}

.dashboard-alt #map .marker-popup {
    display: flex;
    flex-direction: column;
    gap: 3px;


}

.dashboard-alt #map .marker-popup .marker-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;

    white-space: nowrap;

    line-height: 1;
}

.dashboard-alt #map .marker-popup .marker-title #title {
    font-weight: bold;
}

.dashboard-alt #map .marker-popup .marker-title #tms {
    font-size: 70%;
}

.map-content {
    position: absolute;

    top: 0%;
    right: 0%;

    z-index: 5000;

    display: flex;

    gap: 12px;

    height: 100%;
}

.map-actions {
    position: absolute;

    top: 1%;
    right: 80px;

    z-index: 2000;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    height: 50px;

    gap: 12px;

    font-size: 16pt;
}

.map-actions .main-button {
    min-width: 50px;

    padding: 6px 12px;
}

.map-actions #report-now-button-container {
    display: flex;
    align-items: center;

    gap: 12px;

    color: var(--dark-gray);
}

.map-actions #report-now-button-container .timer {
    display: flex;
    align-items: center;

    gap: 6px;

    background-color: white;

    padding: 6px;

    border-radius: 5px;

    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
}

.map-actions #report-now-button-container .timer .icon {
    color: var(--secondary-warning);
}

.map-actions #dates,
.map-actions #actions {
    display: flex;
    align-items: center;
    gap: 12px;
}

.map-actions #dates {
    background-color: white;
    padding: 3px;
    border-radius: 5px;

    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 460px) {
    .map-actions {
        right: 60px;

    }

    .map-actions #dates {
        flex-direction: row;
    }

    .map-actions #dates input{
        font-size: 10pt;
    }
}

.user-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
}

.user-menu #top .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 12px;

    width: 100%;
    height: fit-content;

    padding: 12px;

    background-color: var(--terciary-gray);

    white-space: nowrap;
}

.user-menu #top .user-info #icon {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 100px;
    aspect-ratio: 1/1;

    font-size: 36pt;

    border: solid 4px var(--dark-gray);
    border-radius: 100%;

    background-color: white;
}

.user-menu #logout {
    all: unset;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    gap: 6px;

    width: 100%;

    height: 60px;

    padding: 12px;

    gap: 12px;

    transition: background-color 0.2s;

    font-size: 16pt;

    white-space: nowrap;
}

.user-menu #logout:hover {
    background-color: var(--main-gray);
    cursor: pointer;
}

.user-menu #logout.active {
    background-color: var(--main-gray);
}

#user-menu #logout>:first-child {
    width: 10%;
}

.reports-menu {
    overflow: hidden;
    height: 100%;
}

.reports-menu #last-reports {
    display: flex;
    flex-direction: column;
    gap: 12px;

    height: 100%;
    overflow: hidden;
}

.reports-menu #last-reports #counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    padding: 6px;

    text-align: center;
}

.reports-menu #last-reports #counter>span {
    text-transform: uppercase;
}

.reports-menu #last-reports #counter :first-child {
    font-size: 24pt;
    font-weight: bold;
}

.reports-menu #last-reports .report-list {
    display: flex;
    flex-direction: column;
    gap: 6px;

    height: 100%;

    overflow-y: scroll;

    padding: 3px;
    padding-left: 12px;
}

.report {
    display: grid;
    grid-template-columns: 3fr 1fr;

    width: 100%;

    background-color: white;

    padding: 6px;

    gap: 6px;

    border-radius: 5px;

    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
}

.report .general-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
}

.report .general-info .top {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.report .report-info {
    display: flex;
    flex-direction: column;

    font-size: 12pt;

    font-weight: bold;

    color: var(--base-highlight-color);
}

.report .report-info :last-child {
    font-size: 8pt;
    font-weight: normal;
    white-space: nowrap;
}

.report .report-type {
    display: flex;
    align-items: center;

    gap: 3px;

    background-color: var(--mid-color);

    border-radius: 15px;

    padding: 3px 12px;

    color: white;
    font-weight: bold;
}

.report .report-description {
    font-size: 10pt;
}

.report .report-author {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-size: 10pt;

    background-color: var(--terciary-gray);

    padding: 3px 6px;

    border-radius: 5px;

    width: fit-content;
}

.report .report-author>span:first-child {
    color: var(--dark-gray);
    font-size: 8pt;
}

.report .report-type-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 6px;

    font-size: 10pt;
}

.report .left {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: bold;
}

.noise-report .decibels {
    display: flex;
    height: 60px;
}

.noise-report .decibels #markers {
    position: relative;

    width: 30px;
}

.noise-report .decibels #markers .marker {
    display: flex;
    align-items: center;
    width: fit-content;

    position: absolute;

    right: 5%;
}

.noise-report .decibels #markers .marker>span {
    font-size: 8pt;
    white-space: nowrap;
}

.noise-report .decibels #markers .marker#max-marker {
    color: red;
}

.noise-report .decibels #markers .marker#min-marker {
    color: green;
}

.noise-report .decibels-info {
    display: flex;

    gap: 6px;

    font-size: 10pt;


}

.noise-report .decibels-info>span {
    display: flex;
    align-items: center;
    gap: 3px;

    background-color: var(--terciary-gray);

    padding: 3px 6px;

    white-space: nowrap;

    border-radius: 10px;
}

.noise-report .decibels-info>span.max {
    background-color: color-mix(in srgb, transparent 90%, red);
    color: red;
}

.noise-report .decibels-info>span.min {
    background-color: color-mix(in srgb, transparent 90%, green);
    color: green;
}

.noise-report .decibels-info>span.duration {
    color: var(--dark-gray);
}

.noise-report #meter {
    height: 100%;
    width: 30px;

    border: solid 4px var(--secondary-gray);

    background: linear-gradient(0deg, rgba(92, 255, 80, 1) 0%, rgba(205, 214, 43, 1) 50%, rgba(255, 0, 0, 1) 100%);
}

.noise-report.on-marker {
    padding: 0px;

    box-shadow: none;
}

.noise-report.on-marker .report-author {
    font-size: 80%;
}

.noise-report.on-marker .report-author>span:first-child {
    font-size: 70%;
}

.noise-report.on-marker .decibels-info {
    flex-direction: column;
}

.noise-report.on-marker .decibels {
    display: none;
}

@media screen and (max-width: 460px) {
    #map {
        width: calc(100% - 50px) !important;
    }

    .map-sidebar {
        max-width: 300px;
        width: 50px !important;
    }

    .map-sidebar.extended {
        width: 300px !important;
    }

    .map-sidebar .menus {
        width: 50px !important;
    }

    .map-sidebar .menus .sidebar-menu-item {
        font-size: 7pt !important;
    }

    .map-sidebar .menus .sidebar-menu-item .icon {
        font-size: 10pt;
    }

    .noise-report .decibels-info {
        flex-direction: column;
    }

    .map-actions #actions {
        align-items: flex-start;
    }

    .map-actions #report-now-button-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    
        gap: 12px;
    
        color: var(--dark-gray);
    }

    .map-actions #markers-button {
        max-height: 50px;
    }
}