.navbar {
    position: fixed;
    top: 0%;
    left: 0%;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: var(--navbar-height);

    background-color: var(--main-gray);

    z-index: 2000;

    overflow: hidden;

    .right,
    .left {
        display: flex;
        align-items: center;

        gap: 6px;

        height: 100%;
    }
}

.navbar-item {
    all: unset;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 6px;

    height: 100%;
    aspect-ratio: 1/1;

    font-size: 18pt;

    font-weight: bold;

    transition: all 0.2s;

    &:hover {
        background-color: var(--secondary-gray);
        cursor: pointer;
        font-size: 22pt;
    }
}

.sidebar {
    position: fixed;
    width: 100%;
    height: 100%;

    z-index: 3000;

    overflow: hidden;

    max-width: 2000px;
    opacity: 100;

    transition: opacity 0.1s;

    &:not(.open) {
        max-width: 0;
        opacity: 0;

        transition: max-width 0.1s 0.1s, opacity 0.1s;
    }

    .background {
        position: absolute;
        width: 100%;
        height: 100%;

        background-color: rgba(0, 0, 0, 0.5);

        opacity: 100;
    }

    .sidebar-content {
        position: absolute;

        display: flex;
        flex-direction: column;

        width: var(--sidebar-width);
        height: 100%;

        background-color: white;

        z-index: 3001;

        overflow: hidden;
    }
}

.sidebar-item {
    all: unset;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    gap: 6px;

    width: 100%;

    height: 60px;

    padding: 12px;

    gap: 12px;

    width: var(--sidebar-width);

    transition: background-color 0.2s;

    font-size: 16pt;

    &:hover {
        background-color: var(--main-gray);
        cursor: pointer;
    }

    &.active {
        background-color: var(--main-gray);
    }

    > :first-child {
        width: 10%;
    }
}

.left-sidebar {
    .sidebar-content {
        left: 0;
    }
}

.right-sidebar {
    direction: rtl;

    .sidebar-content {
        right: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;

        > * {
            direction: ltr;
        }

        #top {
            .user-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                gap: 12px;

                width: 100%;
                height: fit-content;

                padding: 12px;

                background-color: var(--terciary-gray);

                #icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 50%;
                    min-width: 100px;
                    aspect-ratio: 1/1;

                    font-size: 36pt;

                    border: solid 4px var(--dark-gray);
                    border-radius: 100%;

                    background-color: white;
                }
            }
        }

        #bottom {
            #logout {
                &:hover {
                    background-color: var(--main-red);
                }
            }
        }
    }
}

.signout-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 24px;

    .actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;

        width: 100%;
    }
}