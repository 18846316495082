.main-modal {
	position: fixed;

	top: 0%;
	left: 0%;

	z-index: 9000;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
	opacity: 100;

	background-color: rgba(0, 0, 0, 0.5);

	transition: opacity 0.2s;

}

.main-modal:not(.visible) {
	width: 0;
	height: 0;
	opacity: 0;

	/* z-index: -100; */

	transition: opacity 0.5s, width 0.5s 0.5s, height 0.5s 0.5s;

}

.main-modal:not(.visible) .modal-container {
	max-width: 0px !important;
	max-height: 0px !important;

	transition: max-height 0.75s, max-width 0.75s;
}

.main-modal .modal-container {
	display: flex;
	flex-direction: column;

	width: fit-content;
	height: fit-content;

	max-width: 90%;
	max-height: 90%;

	background-color: white;
	border-radius: 10px;

	transition: max-height 1s, max-width 1s;

	box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);

	padding-bottom: 24px;

}

.main-modal .modal-container .modal-top {
	display: flex;
	align-items: center;
	justify-content: space-between;

	white-space: nowrap;

	width: 100%;

	gap: 24px;

	color: white;

	font-weight: bold;

	border-top-left-radius: 5px;
	border-top-right-radius: 5px;

	background-color: white;
}

.main-modal .modal-container .modal-top .title {
	display: flex;
	padding: 6px;

	background-color: var(--secondary-gray);

	border-bottom-right-radius: 5px;
	border-top-left-radius: 5px;
}

.main-modal .modal-container .modal-top .main-button {
	border-radius: 0px;

	border-top-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.main-modal .modal-container .modal-content {
	padding: 10px;
	height: 100%;
	width: 100%;

	overflow-y: scroll;
}

@media screen and (max-width: 460px) {
	.main-modal .modal-container {
		width: 100%;
	}
}