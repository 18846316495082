@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
	box-sizing: border-box;
	font-family: 'IBM Plex Sans', sans-serif;
}

:root {
	--navbar-height: 60px;

	--main-gray: #CDD4DC;
	--base-color: var(--main-gray);

	--secondary-gray: #969DA7;
	--mid-color: var(--secondary-gray);
	
	--terciary-gray: #e0e6ec;
	--dark-gray: #333333;
	--base-highlight-color: var(--dark-gray);

	--main-green: rgb(63, 183, 63);
	--highlight-color: var(--main-green);

	--main-warning: rgb(252, 174, 17);
	--secondary-warning: rgb(201, 144, 31);

	--ok-green: rgb(0, 196, 0);
	--secondary-ok-green: rgb(1, 158, 1);

	--main-red: rgb(192, 53, 34);
	--secondary-red: rgb(163, 47, 31);
	
	--sidebar-width: 300px;
}

body,
html {
	margin: 0;

	height: 100%;
	width: 100%;
}

#root {
	overflow: hidden;
}

body {
	overflow-y: scroll;
}

h1.title {
	margin: 0;
}
h2.title {
	margin: 0;
}
h3.title {
	margin: 0;
}

.page-container {
	margin-top: calc(var(--navbar-height));
	padding: 24px 5%;
}

.rect-container {
	background-color: var(--main-gray);
	border-radius: 5px;
}

.base-shadow {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 16%);
}

/* width */
*::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
	background: #555;
}

*.scroll-left {
	direction: rtl;

	>* {
		direction: ltr;
	}

	&::-webkit-scrollbar {
		position: absolute;
		left: 0%;
	}
}

@media screen and (max-width: 460px) {
	.page-container {
		padding: 12px;
	}
}