.sign-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 24px;

    min-width: 400px;

}

.sign-modal .form {
    display: flex;
    flex-direction: column;

    gap: 16px;

}

.sign-modal .form #inputs {
    display: flex;
    flex-direction: column;

    gap: 6px;

}

.sign-modal .form #inputs #password {
    display: flex;
    flex-direction: column;
    gap: 6px;

}

.sign-modal .form #inputs #password #recovery {
    all: unset;
    cursor: pointer;

    font-size: 10pt;

    color: var(--base-highlight-color);

}

.sign-modal .form #inputs #password #recovery:hover {
    text-decoration: underline;
}

.sign-modal .form #actions {
    display: flex;
    flex-direction: column;

}

.sign-modal .form #actions #signup,
.sign-modal .form #actions #signin {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin-top: 12px;

    font-size: 10pt;

    gap: 6px;

}

.sign-modal .form #actions #signup>span,
.sign-modal .form #actions #signin>span {
    color: var(--secondary-gray);
    white-space: nowrap;
}

.sign-modal .form #actions #signup .main-button,
.sign-modal .form #actions #signin .main-button {
    color: var(--highlight-color);
}

.sign-up-modal .form #inputs #names {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
}

.sign-up-modal .form #inputs #user_id {
    display: grid;
    grid-template-columns: 0.5fr 1fr;

    gap: 6px;
}

@media screen and (max-width: 460px) {
    .sign-in-modal,
    .sign-up-modal {
        min-width: 100%;
    }
}