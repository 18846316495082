:root {
	--main-select-font-size: 14pt;
}


.main-select {
	position: relative;

	display: flex;
	flex-direction: column;
	gap: 2px;

	width: fit-content;

	font-size: var(--main-input-font-size);

}

.main-select .description {
	white-space: nowrap;
	font-size: calc(var(--main-select-font-size) - 4pt);
	color: var(--mid-color);

	max-width: 90%;

	overflow: hidden;
	text-overflow: ellipsis;
}

.main-select:not(.error):has(input:hover:not(:disabled, :focus)) .input-container {
	background-color: var(--main-gray);
}

.main-select .input-container {
	display: flex;
	align-items: center;

	border: none;
	border-radius: 5px;

	background-color: var(--terciary-gray);

}

.main-select .input-container input {
	width: 100%;

	font-size: var(--main-select-font-size);
	border: none;
	border-radius: 5px;
	padding: 3px;
	margin: 3px;

	background-color: transparent;

	color: var(--dark-gray);

}

.main-select .input-container input:focus {
	outline: none;
	border: none;
}

.main-select .input-container input::placeholder {
	color: var(--secondary-gray);
}

.main-select .input-container:has(input:focus) {
	background-color: color-mix(in srgb, var(--highlight-color), white 70%);
	;
}

.main-select .input-container:has(input:disabled) {
	background-color: var(--terciary-gray);

}

.main-select .input-container:has(input:disabled) input {
	cursor: default;
	background-color: var(--terciary-gray);
}

.main-select .input-container:has(input:disabled) .main-button {
	cursor: default;
}

.main-select .input-container:has(input:disabled) .input-buttons {
	display: none
}

.main-select .input-container:has(input:disabled):hover .input-buttons {
	display: flex;
}

.main-select .input-container .input-buttons {
	position: absolute;
	right: calc(0% + 24px);
	top: 60%;

	display: flex;
	gap: 3px;

	z-index: 6;

}
.main-select .input-container .input-buttons .main-icon-button {
	width: 25px;
	height: 25px;
	font-size: 10pt;

	box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.main-select .input-container #dropdown {
	color: var(--dark-gray);
}

.main-select .search-list {
	position: absolute;
	top: calc(100% + 2px);

	z-index: 5;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	height: fit-content;
	min-width: 150px;
	width: 100%;

	box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.25);
	background-color: white;

	max-height: 155px;

	overflow-y: scroll;

	color: var(--dark-gray);

}
.main-select .search-list .select-item {
	padding: 3px;
	width: 100%;

	background-color: var(--terciary-gray);
}
.main-select .search-list .select-item.selected {
	background-color: var(--base-highlight-color);

	color: white;

}
.main-select .search-list .select-item.selected:hover {
	background-color: var(--base-highlight-color);
}

.main-select .search-list .select-item.empty:hover {
	background-color: var(--terciary-gray) !important;
}

.main-select .search-list .select-item:hover {
	cursor: pointer;

	background-color: var(--secondary-gray);
}

.main-select .search-list .select-item.disabled {
	cursor: not-allowed;

	color: var(--main-gray);	
}
.main-select .search-list .select-item.disabled:hover {
	background-color: var(--terciary-gray);
}