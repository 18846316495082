:root {
	--notification-icon-color: var(--ok-green);
}

:root .notification-popup.normal {
	--notification-icon-color: var(--ok-green);
}

:root .notification-popup.error {
	--notification-icon-color: var(--main-red);
}

:root .notification-popup.warning {
	--notification-icon-color: var(--main-warning);
}

:root .notification-popup.loading {
	--notification-icon-color: var(--main-gray);
}

.notification-container {
	position: absolute;

	top: 95%;
	left: 50%;
	transform: translate(-50%, -95%);

	white-space: wrap;
	width: 100%;
	max-width: 450px;
	max-height: 400px;
	overflow: hidden;

	padding: 16px;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 6px;

	z-index: 10000;

}

.notification-container .close-manual:hover {
	cursor: pointer;

	filter: brightness(85%);
}

.notification-container:not(:has(.notification-popup)) {
	display: none;
}

.notification-popup {
	background-color: white;

	border-radius: 10px;
	/* border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; */

	box-shadow: 0px 6px 12px 3px rgba(0, 0, 0, 0.3);

	width: 100%;
	min-height: 70px;

	transition: width 0.3s, opacity 0.3s;

	overflow: hidden;
}
.notification-popup .content {
	display: flex;
	align-items: center;
	gap: 24px;
	padding: 12px;
}

.notification-popup.hidden {
	white-space: nowrap;
	opacity: 0;
	width: 0;
}

.notification-popup .icon {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 35px;
	aspect-ratio: 1/1;

	border-radius: 100%;

	color: white;

	background-color: var(--notification-icon-color);
}

.notification-popup .status-bar {
	width: 100%;
	height: 10px;

	background-color: var(--notification-icon-color);
}
