.map-sidebar {
    cursor: default;

    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;

    width: 70px;
    height: 100%;

    background-color: white;

    overflow: hidden;

    transition: width 0.3s;

}
.map-sidebar .menus {
    display: flex;
    flex-direction: column;

    width: 70px;
    height: 100%;

    border: solid 1px var(--terciary-gray);

}
.map-sidebar .menus .sidebar-menu-item {
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 6px;

    width: 100%;
    aspect-ratio: 1/1;

    padding: 12px;

    color: var(--dark-gray);
    font-size: 10pt;
    font-weight: bold;

    transition: background-color 0.2s;

}
.map-sidebar .menus .sidebar-menu-item .icon {
    font-size: 16pt;
}

.map-sidebar .menus .sidebar-menu-item:hover {
    background-color: var(--terciary-gray);
}

.map-sidebar .menus .sidebar-menu-item.selected {
    background-color: var(--highlight-color);
    color: white;
}

.map-sidebar .sidebar-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.map-sidebar.extended {
    width: 300px;
}
.map-sidebar.extended:has(.reports-menu) {
    width: 500px;
}